import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'fullscreen-polyfill'
import Backbone from '@polleverywhere/backbone'
import $ from 'jquery'
import _ from 'underscore'
import _str from 'underscore.string'
import URI from 'urijs'
import XDate from 'xdate'
import PollEv from '@polleverywhere/core/helpers/core.js'

// polyfills for cutlass
import 'mdn-polyfills/Element.prototype.closest'
import 'mdn-polyfills/Element.prototype.matches'
import { contextualize } from '@polleverywhere/cutlass'

import formatMessage from 'format-message'
import formatMessageGenerateId from 'format-message-generate-id/underscored_crc32'

import '@polleverywhere/fullscreen-api'

window.formatMessage = formatMessage
window.formatMessageGenerateId = formatMessageGenerateId
window.jQuery = window.$ = $
window.Backbone = Backbone
window.Backbone.$ = $
window._ = _
window._.str = _str
window.URI = URI
window.PollEv = PollEv
window.XDate = XDate

// Copy of jquery.meta.js.coffee for the webpack world
$.extend({
  meta (key) {
    return $(`meta[name='${key}']`).attr('content')
  }
})

// contextualize cutlass dom events
contextualize(document.body, 'rails')
